
import { defineComponent, ref } from "vue";
import useSpotCheckingOverview from "@/hooks/checking/spotChecking/useSpotCheckingOverview";
import { elementSize } from "@/utils";
import BuSelector from "@/views/Payment/components/BuSelector.vue";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
import GetColumnWidth from '@/utils/payment/getColumnWidth'
export default defineComponent({
  name: "paymentApproveMgmt",
  components: {
    // UpOutlined
    BuSelector,
    EntitySelector,
  },
  props: {},
  setup() {
    //#region [引用useApprovalMain.ts相关]
    const {
      searchParams,
      dealerColData,
      afcAndLcColData,
      dealerTblData,
      afcAndLcTblData,
      doSearch,
      doReset,
      toDealerPage,
      toFinancePage,
    } = useSpotCheckingOverview();

    const tableSize = elementSize(".ant-table-wrapper");
    const dealerTableWidth = ref(GetColumnWidth(dealerColData))
    const flTableWidth = ref(GetColumnWidth(afcAndLcColData))
    return {
      searchParams,
      dealerColData,
      afcAndLcColData,
      dealerTblData,
      afcAndLcTblData,
      doSearch,
      doReset,
      toDealerPage,
      toFinancePage,
      tableSize,
      dealerTableWidth,
      flTableWidth
    };
  },
});
