import { ref, reactive, computed, onMounted } from "vue";
import * as API from "@/API/checking/spotChecking";
import { SearchParams, DealerTableData, AFCandLCTableData } from "@/views/Checking/spotChecking/spotCheckingControlling/spotCheckOverview/types";
import moment from 'moment';
import useFetch from "@/hooks/useFetch";
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { getSubscribedBu } from "@/API/approve";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const useVehicleNameSet = () => {
    const { commit } = useStore()
    const router = useRouter()

    const startDate = moment()
        .month(moment().month() - 1)
        .startOf("month")
        .format("YYYY-MM-DD");
    const endDate = moment()
        .month(moment().month() + 1)
        .endOf("month")
        .format("YYYY-MM-DD");

    const searchParams = reactive<SearchParams>({
        entity: undefined,
        bu: undefined,
        submissionPeriod: [startDate, endDate],
        priority: undefined
    })

    const dealerColData = computed(() => {
        return [
            { title: "Package Code", dataIndex: "packageCode", slots: { customRender: 'packageCode' }, width: 150 },
            { title: "Program No.", dataIndex: "programNo", align: 'right', width: 150 },
            { title: "", dataIndex: "blank", width: 50 },
            { title: "Time Line From", dataIndex: "beginDate", slots: { customRender: 'beginDate' }, width: 150 },
            { title: "To", dataIndex: "endDate", slots: { customRender: 'endDate' }, width: 150 },
            { title: "Preparation", dataIndex: "isPreparation", slots: { customRender: 'isPreparation' }, align: 'center', width: 150 },
            // { title: "Check Point&Cal.", dataIndex: "isCheckPoint", slots: { customRender: 'isCheckPoint' }, align: 'center', width: 150 },
            { title: "Logic Check", dataIndex: "isCheckPoint", slots: { customRender: 'isCheckPoint' }, align: 'center', width: 150 },
            { title: "Sample Check", dataIndex: "isSampleCheck", slots: { customRender: 'isSampleCheck' }, align: 'center', width: 150 },
            // { title: "Payout Preparation", dataIndex: "isPayoutPreparation", slots: { customRender: 'isPayoutPreparation' }, align: 'center', width: 150 },
            { title: "Process Status", dataIndex: "status", slots: { customRender: 'status' }, align: 'center', width: 150 }
        ]
    })
    const afcAndLcColData = computed(() => {
        return [
            { title: "Payout Code", dataIndex: "payoutCode", slots: { customRender: 'payoutCode' }, width: 190 },
            { title: "Program No.", dataIndex: "programNo", align: 'right', width: 150 },
            { title: "", dataIndex: "blank", width: 50 },
            { title: "Time Line From", dataIndex: "beginDate", slots: { customRender: 'beginDate' }, width: 150 },
            { title: "To", dataIndex: "endDate", slots: { customRender: 'endDate' }, width: 150 },
            { title: "Preparation", dataIndex: "isPreparation", slots: { customRender: 'isPreparation' }, align: 'center', width: 150 },
            { title: "Check Point&Cal.", dataIndex: "isCheckPoint", slots: { customRender: 'isCheckPoint' }, align: 'center', width: 150 },
            { title: "Sample Check", dataIndex: "isSampleCheck", slots: { customRender: 'isSampleCheck' }, align: 'center', width: 150 },
            // { title: "Payout Preparation", dataIndex: "isPayoutPreparation", slots: { customRender: 'isPayoutPreparation' }, align: 'center', width: 150 },
            { title: "Process Status", dataIndex: "status", slots: { customRender: 'status' }, align: 'center', width: 150 }
        ]
    })
    const dealerTblData = ref<DealerTableData[]>()
    const afcAndLcTblData = ref<AFCandLCTableData[]>()
    const getTableData = (type: string) => {
        const params = {
            entity: searchParams.entity ? searchParams.entity : '',
            bu: searchParams.bu ? searchParams.bu : '',
            beginDate: searchParams.submissionPeriod[0],
            endDate: searchParams.submissionPeriod[1],
            // priority -1 all
            priority: searchParams.priority ? searchParams.priority : -1,
            type: 1
        }
        if (type === 'dealer') {
            params.type = -1
            API.getSpotCheckingStatusList(params).then((data: any) => {
                dealerTblData.value = data
            })
        } else {
            params.type = 1
            API.getSpotCheckingStatusList(params).then((data: any) => {
                afcAndLcTblData.value = data
            })
        }
    }
    const doSearch = () => {
        dealerTblData.value = []
        afcAndLcTblData.value = []
        getTableData('dealer')
        getTableData('afcAndLc')
    }
    const doReset = () => {
        Object.assign(searchParams, {
            entity: undefined,
            bu: undefined,
            submissionPeriod: [startDate, endDate],
            priority: undefined
        })
        doSearch()
    }

    const toDealerPage = (record: DealerTableData) => {
        const info = {
            id: record.packageCodeId,
            packageCode: record.packageCode,
            submitProgramNo: record.programNo,
            // claimNo: record.claimNo,
            // priority: record.priority,
            // assginee: record.assginee,
            timeLineFrom: record.beginDate,
            timeLineTo: record.endDate,
            type: 'overview'
        }
        commit("spotChecking/updateSpotCheckConfirmPackageDealer", info);
        router.push("/spotCheckConfirmProgramDealer");
    }

    const toFinancePage = (record: AFCandLCTableData) => {
        const info = {
            id: record.payoutCodeId,
            payoutCode: record.payoutCode,
            programNo: record.programNo,
            // totalVinNo: record.totalVinNo,
            submitProgramNo: record.programNo,
            // priority: record.priority,
            // assignee: record.assignee,
            // checkStatus: record.checkStatus,
            timeLineFrom: record.beginDate,
            timeLineTo: record.endDate,
            type: 'overview'
        }
        commit("spotChecking/updateSpotCheckConfirmPackageFl", info);
        router.push({ path: "/spotCheckConfirmProgramFl" });
    }
    onMounted(() => {
        doSearch()
    })
    return {
        searchParams,
        dealerColData,
        afcAndLcColData,
        dealerTblData,
        afcAndLcTblData,
        doSearch,
        doReset,
        toDealerPage,
        toFinancePage
    }
}
export default useVehicleNameSet